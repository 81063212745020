/* About Pario Card - Landing Page */

// External
import Image from "next/image";

// styles
import styles from "./styles.module.scss";

export interface AboutPario {
  id: string;
  title: string;
  description: string[];
}

export default function AboutPario({ id, title, description }: AboutPario) {
  return (
    <div className={styles.AboutPario}>
      <div>{title}</div>
      {description.map((line) => (
        <p key={line}>{line}</p>
      ))}
      <Image
        className={styles.absolute}
        src={`/${id}.png`}
        alt={id}
        width={250}
        height={175}
        key={id}
      />
    </div>
  );
}
