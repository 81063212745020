/* About Pario Card - Landing Page */

// External
import { Raleway } from "next/font/google";

// styles
import styles from "./styles.module.scss";

const font = Raleway({
  weight: ["400", "500", "700"],
  subsets: ["latin"],
});

export interface StatFigure {
  figure: string;
  caption: string;
  description: string;
}

export default function StatFigure({
  figure,
  caption,
  description,
}: StatFigure) {
  return (
    <div className={styles.StatFigure} key={figure}>
      <div className={styles.figure}>
        <h3 className={font.className}>{figure}</h3>
        <p>{caption}</p>
      </div>
      <div className={styles.description}>{description}</div>
    </div>
  );
}
