/* A place to store application copy */
import { PRODUCT_OFFERINGS } from ".";
// Landing page icon card copy
const COPY = {
  PRODUCT: [
    {
      id: PRODUCT_OFFERINGS.INTAKE,
      title: "Simplified patient intake and engagement",
      description: [
        "Automated, templatized patient information packets sent with one click",
        "Quickly send and receive consent forms for e-signature, directly to and from patient chart",
        "Send educational materials directly from a content library that patients can also peruse by topic area",
      ],
    },
    {
      id: PRODUCT_OFFERINGS.SCHEDULE,
      title: "Instant Scheduling",
      description: [
        `Easily schedule a patient with full visibility across providers and locations`,
        `Create clinic and provider schedules easily to know who is where, when, at all times`,
      ],
    },
    {
      id: PRODUCT_OFFERINGS.CHART,
      title: "Fit for purpose charting within one record of health",
      description: [
        `Data continuity across encounters: one chart per patient`,
        `Intuitive & templated flows for prenatal, labor, postpartum, and gyn visits`,
        `Voice recognition dictation for times when full attention must be placed on the patient`,
        `Auto-generated invoices and superbills`,
      ],
    },
    {
      id: PRODUCT_OFFERINGS.ORDER,
      title: "Integrated ordering ",
      description: [
        `Order labs, prescriptions, and referrals directly from a patient’s chart`,
        `Eliminate the need for multiple point solutions and data re-entry`,
      ],
    },
  ],
  STATS: [
    {
      figure: "~4x",
      caption: "The US rate of maternal mortality vs other developed nations",
      description:
        "Women in the US are the most likely to die from complications related to pregnancy or childbirth",
    },
    {
      figure: "40%",
      caption: "The increase in maternal mortality over the last year",
      description: "Maternal mortality is rapidly increasing",
    },
    {
      figure: "14%",
      caption: "The percentage of high risk pregnancies",
      description: "High risk pregnancies are on the rise",
    },
    {
      figure: "22k",
      caption: "The deficit of OB/GYNs expected by 2050",
      description:
        "A shortage of doctors is exacerbating the maternal health crisis",
    },
  ],
};

export default COPY;
