/* Pario Marketing Landing Page */

/* External */
import { ReactElement } from "react";
import Head from "next/head";
import Image from "next/image";
import clsx from "clsx";
import { Raleway } from "next/font/google";

/* Components */
import Layout from "@/components/layout";
import ButtonLink from "@/components/button/link";
import AboutPario from "@/components/cards/aboutPario";
import RotatingWords from "@/components/rotatingWords";
import StatFigure from "@/components/cards/statFigure";

/* Constants */
import { LAYOUTS } from "@/globals/constants";
import C from "@/globals/constants/copy";
import heroImage from "../../public/hero.png";

/* Styles */
import styles from "@/styles/Landing.module.scss";

const font = Raleway({
  weight: ["400", "500", "700"],
  subsets: ["latin"],
});

export default function Landing() {
  return (
    <>
      {/* Metadata */}
      <Head>
        <title>Pario - EMR for Women's Health</title>
        <meta
          name="description"
          content="The platform for women's health practitioners"
        />
        <meta
          property="og:title"
          content="Pario - EMR for Women's Health"
          key="ogtitle"
        />
        <meta
          property="og:description"
          content="The platform for women's health practitioners"
          key="ogdesc"
        />
        <meta name="twitter:title" content="Pario - EMR for Women's Health" />
        <meta
          name="twitter:description"
          content="The platform for women's health practitioners"
        />
      </Head>
      {/* Main content */}
      <main className={clsx(styles.Landing)}>
        {/* Section 1: Branding */}
        <section className={clsx(styles.page)}>
          <div className={clsx(styles.gridCol2)}>
            <div>
              <h1 className={clsx(font.className)}>
                <RotatingWords
                  prefix="Make"
                  words={["intake", "scheduling", "charting", "ordering"]}
                  suffix="easy with Pario"
                />
              </h1>
              <p>The modern, HIPAA compliant EMR for women's health</p>
              <p>Your partner in practice</p>
              <ButtonLink style="callout" href="mailto:maggie@pariohealth.com">
                {"Contact Us >"}
              </ButtonLink>
            </div>
            <div className={styles.imageContainer}>
              <Image
                className={styles.heroImage}
                src={heroImage}
                alt="doctor with pregnant patient"
                priority
              />
            </div>
          </div>
        </section>
        {/* Section 2: Product Offerings */}
        <section className={clsx(styles.page, styles.secondary)}>
          <div className={styles.title}>
            <h2 className={clsx(font.className)}>
              <RotatingWords
                prefix="Pario uses"
                words={[
                  "technology",
                  "automation",
                  "dictation",
                  "customization",
                ]}
                suffix="to streamline your work"
              />
            </h2>
          </div>
          <div className={styles.gridCol4}>
            {C.PRODUCT.map(({ id, title, description }) => (
              <AboutPario
                key={id}
                id={id}
                title={title}
                description={description}
              />
            ))}
          </div>
          {/* Zygote gif */}
          <div className={styles.gifContainer}>
            <Image
              className={styles.zygote}
              src="/gifs/zygote-division.gif"
              alt="zygote division gif"
              width={500}
              height={200}
            />
          </div>
        </section>
        <section className={clsx(styles.page, styles.secondary)}>
          <div className={styles.title}>
            <h2 className={font.className}>Maternal health is in crisis</h2>
          </div>
          {/* parent grid */}
          <div className={styles.maternalHealthGrid}>
            <div>
              {C.STATS.map(({ figure, caption, description }) => (
                <StatFigure
                  key={caption}
                  figure={figure}
                  caption={caption}
                  description={description}
                />
              ))}
            </div>
            <div className={styles.heroFigure}>
              <h3 className={font.className}>75%</h3>
              <p>The percent of OB/GYNs experiencing burnout</p>
              <div>
                OB/GYNs are exhausted, demotivated, and lack sufficient tools
              </div>
              <div>
                EHRs have been cited as a <strong>leading factor </strong>in
                physician burnout
              </div>
              <p>That's why we built Pario</p>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

// Custom layout for landing page
Landing.getLayout = function getLayout(page: ReactElement) {
  return <Layout type={LAYOUTS.LANDING}>{page}</Layout>;
};
