/* Button Component */

// External
import clsx from "clsx";

// styles
import styles from "./styles.module.scss";

export interface RotatingWords {
  prefix: string;
  words: string[];
  suffix: string;
}

export default function RotatingWords({
  prefix,
  words,
  suffix,
}: RotatingWords) {
  return (
    <span className={clsx(styles.RotatingWords)}>
      {prefix}
      {words.map((word) => (
        <span key={word}>{word}</span>
      ))}
      <br />
      {suffix}
    </span>
  );
}
